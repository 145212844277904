.container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    background: var(--color-white);

    .button {
        /* padding: 0.5rem 0.875em 0.4375rem; */
        margin-right: var(--spacing-small);
        margin-bottom: var(--spacing-small);
    }

    .selected {
        background: var(--color-black);
        color: var(--color-white);
    }
}
