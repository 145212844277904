.container {
    .items {
        position: relative;
    }

    .item {
        z-index: 0;
        padding: 0 var(--menu-spacing-large);
    }

    .alphabet {
        position: absolute;
        z-index: 2;
        right: 0;
        display: flex;
        flex-direction: column;
        padding: var(--menu-spacing) 0;
        margin-top: var(--menu-spacing-extra-large);
        background: white;
    }

    .alphabetLetter {
        padding: 0.0625rem var(--menu-spacing-large);
    }

    .letter {
        padding: var(--menu-sub-section-title-padding);
        border-bottom: var(--menu-section-border);
        margin: var(--menu-sub-section-title-margin);
        margin-bottom: var(--menu-spacing);
        font-size: var(--menu-sub-section-title-font-size);
    }

    .card {
        padding: var(--menu-spacing-large) 0;
        border-bottom: var(--menu-section-border);
        /* margin-bottom: var(--menu-spacing); */
    }
}
