.container {
    overflow: hidden;
    padding: var(--card-spacing) 0;
    border-bottom: 0;
    color: inherit;
    font-size: 0.875rem;
    font-weight: var(--typo-menu-section-font-weight);
    text-decoration: none;

    .thumbnail {
        position: relative;
    }

    .icon {
        position: relative;
        width: 2em;
        height: 2em;
    }

    .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: var(--card-spacing);
    }

    .title {
        display: flex;
        height: 100%;
        align-items: center;
        white-space: normal;
    }

    &.withBorder {
        border-bottom: var(--card-border-bottom);
    }

    &.big {
        font-size: var(--card-big-title-font-size);
    }

    &.small {
        font-size: var(--card-small-title-font-size);
    }
}
