.container {
    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--menu-section-title-padding);
        margin: var(--menu-section-title-margin);
        font-size: var(--menu-section-title-font-size);

        a {
            text-decoration: none;
        }

        .icon {
            width: 1em;
            height: 1em;
            margin-left: var(--menu-spacing);
        }
    }

    /* .items {
        margin-top: 10px;
    } */

    .viewAll {
        display: flex;
        justify-content: center;
        margin-top: var(--menu-spacing-extra-large);
    }

    .viewAllButton {
        padding: 0.6em 0.83em 0.5em;
        font-size: 12px;
        line-height: 0.83;
    }
}
