.container {
    position: relative;
    overflow: hidden;

    .content {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: padding-right 0.2s ease-out;
    }

    .buttons {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .subscribeButton {
        display: block;
        height: var(--menu-button-size);
        padding-top: 0.05em;
        padding-bottom: 0;
        margin: var(--side-spacing) 5px 5px;
        background: var(--menu-top-button-background, var(--brand-text-color));
        color: var(--menu-top-button-color, var(--menu-header-color));
        line-height: var(--menu-button-size);
    }

    .accountButton {
        display: block;
        width: calc(var(--menu-button-size) + 5px + 5px);
        height: calc(var(--menu-button-size) + var(--side-spacing) + 5px);
        padding: var(--side-spacing) 5px 5px;
        color: var(--color-white);

        rect {
            fill: var(--menu-top-button-background, var(--brand-text-color));
        }

        path {
            color: var(--menu-top-button-color, var(--menu-header-color));
        }
    }

    .closeButton {
        display: block;
        width: calc(var(--menu-button-size) + 5px + var(--side-spacing));
        height: calc(var(--menu-button-size) + var(--side-spacing) + 5px);
        padding: var(--side-spacing) var(--side-spacing) 5px 5px;

        circle {
            fill: var(--menu-top-button-background, var(--brand-text-color));
        }

        path {
            stroke: var(--menu-top-button-color, var(--menu-header-color));
        }
    }

    &.menuOpened {
        .sidebar {
            transform: translate(0, 0);
        }

        .content {
            padding-right: 300px;
        }
    }

    &.isPanel {
        --menu-top-button-background: var(--color-black);
        --menu-top-button-color: var(--color-white);

        .accountButton {
            display: none;
        }

        .subscribeButton {
            display: none;
        }
    }
}
