.container {
    position: relative;

    .form {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .fieldContainer {
        padding: var(--menu-spacing-large);
    }

    .fieldContainer,
    .field {
        width: 100%;
    }

    .cancelButtonContainer {
        position: absolute;
        left: 100%;
    }

    .cancelButton {
        padding: var(--menu-spacing-large);
        font-size: 0.75em;
    }

    .content {
        display: block;
    }

    .section {
        margin-top: var(--menu-spacing-large);
    }

    .title {
        padding: var(--menu-sub-section-title-padding);
        padding-right: var(--menu-spacing-large);
        padding-left: var(--menu-spacing-large);
        margin: var(--menu-sub-section-title-margin);
        font-size: var(--menu-sub-section-title-font-size);
    }

    .item {
        padding: var(--menu-spacing) 0;
        border-bottom: var(--menu-section-border);
    }

    .items {
        --grid-spacing: var(--spacing-small);
        --row-spacing: var(--spacing-small);

        padding: 0 var(--menu-spacing-large);
    }

    .results {
        .title {
            padding-top: var(--menu-spacing-large);
            padding-right: 0;
            padding-left: 0;
            border-bottom: var(--menu-section-border);
            margin-right: var(--menu-spacing-large);
            margin-left: var(--menu-spacing-large);
        }

        .items {
            margin-bottom: var(--menu-spacing);
        }
    }

    &.linksDisabled {
        a {
            pointer-events: none;
        }
    }

    &.opened {
        .form {
            padding-bottom: var(--menu-spacing-large);
        }
    }
}
