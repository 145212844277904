.container {
    position: relative;
    padding: 0 var(--side-spacing);
    padding-top: 80px;
    background: var(--menu-header-background);
    color: var(--menu-header-color);

    .top {
        position: absolute;
        top: var(--side-spacing);
        left: var(--side-spacing);
        display: flex;
        width: 100%;
        height: var(--menu-header-top-height);
        align-items: center;
        color: var(--menu-header-color);
    }

    .topLogoUrbania {
        display: block;
        width: auto;
        height: var(--menu-urbania-size);
    }

    .logoContainer {
        width: 100%;
        height: 0;
        padding-bottom: 26%;
        margin-bottom: var(--menu-spacing);
    }

    .logoLink {
        display: block;
        width: 100%;
        height: auto;
    }

    .logo {
        display: block;
        width: 100%;
        height: auto;
    }

    .brandingWrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 20%;
    }

    .branding {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .sponsorContainer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .sponsor {
        display: block;
        width: auto;
        height: var(--menu-header-sponsor-height);
        margin: auto;
    }

    .tagline {
        margin: auto 0;
        font-size: var(--menu-header-tagline-font-size);
        text-align: center;
    }

    .subscribe {
        margin-right: 20px;

        &::after {
            display: none;
        }
    }

    .sectionsMenu {
        max-width: 25em;
        margin: 0 auto;
        margin-bottom: var(--menu-spacing-extra-large);
    }

    .sectionItems {
        justify-content: center;
    }

    .sectionPill {
        border: var(--menu-header-section-border, solid 1px var(--brand-primary-color));
        background: var(--menu-header-section-background, var(--menu-header-color));
        color: var(--menu-header-section-color, var(--brand-primary-color));
        font-size: var(--menu-header-section-font-size);
        font-weight: var(--menu-header-pill-font-weight);
    }

    .indexMenu {
        margin-bottom: var(--menu-spacing-extra-large);
    }

    .indexItems {
        justify-content: center;
    }

    .indexPill {
        border: none;
        background: var(--menu-header-index-background, var(--brand-primary-color));
        color: var(--menu-header-index-color, var(--menu-header-color));
        font-size: var(--menu-header-index-font-size);
        font-weight: var(--menu-header-pill-font-weight);
    }

    .linksMenu {
        padding: var(--menu-spacing) 0;
        border-top: var(--menu-header-border, solid 1px var(--menu-header-color));
        font-size: 1rem;
    }

    .linkItem {
        &::after {
            margin: 0 var(--menu-spacing);
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &.hasSponsor {
        .branding {
            justify-content: flex-start;
        }
    }
}

.menuSponsor {
    width: 197px;
    margin: 0 auto;
}
