.container {
    position: relative;

    .items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .item {
        &::after {
            content: '·';
        }

        &:last-child {
            &::after {
                content: '';
            }
        }
    }

    .button {
        text-decoration: none;
    }
}
