.container {
    position: relative;
}

.panelContainer,
.panel {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.panel {
    overflow-y: auto;
    overscroll-behavior: contain;
}

