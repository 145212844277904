.container {
    padding: 0 var(--menu-spacing-large);

    .header {
        position: sticky;
        z-index: 10;
        top: 0;
        width: 100%;
    }

    .toolbar {
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        text-align: center;
    }

    .backButton {
        position: absolute;
        /* top: 0; */
        left: 0;
    }

    .field {
        width: 100%;
        padding: var(--menu-spacing-large) 0;
        background: white;
    }
}
