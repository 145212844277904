.container {
    .header {
        /* padding-bottom: var(--spacing); */
    }

    .items {
        /* position: relative; */
    }

    .item {
        height: 40px;
        padding: 0 var(--menu-spacing-large);
    }

    .letter {
        padding: var(--menu-spacing) 0;
        padding-bottom: var(--menu-spacing);
        border-bottom: var(--menu-section-border);
        margin: 0;
        margin-bottom: var(--menu-spacing);
    }

    .card {
        padding: var(--menu-spacing-large) 0;
        padding-bottom: var(--menu-spacing-large);
        border-bottom: var(--menu-section-border);
        margin-bottom: var(--menu-spacing);
    }
}
